$(document).on('click', '.btn-menu-mobile', function() {
  $(this).toggleClass('open');
  $('body.landing-page').toggleClass('open-menu');
  $('.shared-menu-container ul.menu').toggleClass('mobile-menu');
})

$(document).on('click', '.next-page-icon',function() {
  var top = $("#model").offset().top - 65;
  $('html,body').animate({
      scrollTop: top},
      'slow');
});

$(document).on('click', 'li.menu-item.sub-menu-item',function() {
  var currentItem = document.querySelector('li.sub-menu-item.active');
  if (currentItem !== null) {
    currentItem.classList.remove('active');
    $(this).toggleClass('active');
  } else {
    $(this).toggleClass('active');
  }
});

$(document).on('click', 'li.footer-menu.sub-menu-item',function() {
  var currentItem = document.querySelector('li.footer-menu.active');
  if (currentItem !== null) {
    currentItem.classList.remove('active');
    $(this).toggleClass('active');
  } else {
    $(this).toggleClass('active');
  }
});


window.onscroll = function() {handleScrollHeader()};
$(window).resize(function () {
  var screenWidth = $(window).width();
  handleCloseMobileMenu(screenWidth);
});

function handleCloseMobileMenu(screenWidth) {
  if (screenWidth >= 992) {
    var body = $('body');
    if (body.hasClass('open-menu')) {
      body.removeClass('open-menu');
      $('.btn-menu-mobile').toggleClass('open');
      $('.shared-menu-container ul.menu').toggleClass('mobile-menu');
    }
  }
}

function handleScrollHeader() {
  if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
     $('.shared-header-container').addClass('active');
     $('.shared-header-mobile-container').addClass('active');
  } else {
    $('.shared-header-container').removeClass('active');
    $('.shared-header-mobile-container').removeClass('active');
  }
}
